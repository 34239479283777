import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion

import { numericField } from 'components/animations/utils';


interface Props {
  elementType:         string;
  children?:           React.ReactNode;
  delay?:              number;
  damping?:            number;
  mass?:               number;
  stiffness?:          number;
  [otherProp: string]: any;
}

const AnimationPopIn: React.FC<Props> = (props: Props) => {

  const {
    elementType,  // 'div', 'span', 'img', etc.
    children,     // Children (for an element which can accept children, such as a <div>).
    delay,        // @see https://www.framer.com/api/motion/types/#orchestration.delay
    damping,      // @see https://www.framer.com/api/motion/types/#spring.damping
    mass,         // @see https://www.framer.com/api/motion/types/#spring.mass
    stiffness,    // @see https://www.framer.com/api/motion/types/#spring.stiffness
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  const allProps = {
    ...otherProps,
    initial:    { scale: 0 },  // @see https://www.framer.com/api/motion/animation/#mount-animations
    animate:    { scale: 1 },  // @see https://www.framer.com/api/motion/animation/#target-object
    transition: {              // @see https://www.framer.com/api/motion/animation/#transitions
      delay: numericField(delay, 0),
      scale: {                 // @see https://www.framer.com/api/motion/component/#transform
        type:      'spring',   // @see https://www.framer.com/api/motion/types/#spring
        restSpeed: 0.0001,     // @see https://www.framer.com/api/motion/types/#spring.restspeed
        damping:   numericField(damping,   20),
        mass:      numericField(mass,      1),
        stiffness: numericField(stiffness, 140)
      }
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationPopIn;
