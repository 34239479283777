import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion

import { numericField } from 'components/animations/utils';


type SlideOutFrom = 'top' | 'bottom';

interface Props {
  elementType:         string;
  children?:           React.ReactNode;
  slideOutFrom:         SlideOutFrom;
  delay?:              number;
  damping?:            number;
  mass?:               number;
  stiffness?:          number;
  triggered:           boolean;
  [otherProp: string]: any;
}

const AnimationSlideInVertical: React.FC<Props> = (props: Props) => {

  const {
    elementType,  // 'div', 'span', 'img', etc.
    children,     // Children (for an element which can accept children, such as a <div>).
    slideOutFrom,  // Which side to slide the element in from ('top' or 'bottom').
    delay,        // @see https://www.framer.com/api/motion/types/#orchestration.delay
    damping,      // @see https://www.framer.com/api/motion/types/#spring.damping
    mass,         // @see https://www.framer.com/api/motion/types/#spring.mass
    stiffness,    // @see https://www.framer.com/api/motion/types/#spring.stiffness
    triggered,    // Set this to `false` initially. Change to `true` to trigger the animation.
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  let y: number = 0;

  if (triggered) {
    y = (slideOutFrom === 'top' ? -window.innerHeight : window.innerHeight);
  }

  const allProps = {
    ...otherProps,
    animate:    { y },  // @see https://www.framer.com/api/motion/animation/#target-object
    transition: {                 // @see https://www.framer.com/api/motion/animation/#transitions
      delay: numericField(delay, 0),
      y: {                        // @see https://www.framer.com/api/motion/component/#transform
        type:      'spring',      // @see https://www.framer.com/api/motion/types/#spring
        restSpeed: 0.0001,        // @see https://www.framer.com/api/motion/types/#spring.restspeed
        damping:   numericField(damping,   60),
        mass:      numericField(mass,      0.5),
        stiffness: numericField(stiffness, 100)
      }
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationSlideInVertical;
