import React from 'react';
import { Howl } from 'howler';
import { useTranslation } from 'react-i18next';

import styles from './ScreenQuestion.module.scss';
import AnimationJump from 'components/animations/AnimationJump';
import AnimationPopIn from 'components/animations/AnimationPopIn';
import AnimationPopOut from 'components/animations/AnimationPopOut';
import AnimationSlideIn from 'components/animations/AnimationSlideIn';
import AnimationSlideOut from 'components/animations/AnimationSlideOut';
import { ScreenAnswer } from 'game-constants';
import { PropsScreenWithAnswer } from 'components/screens/screens-common';

import QuestionVideo from './QuestionVideo';

import {
  secsToMs,
  CdnUrlGenerator,
  ClassNamesProcessor,
  makeClassNamesProcessor,
  makePrefixedCdnUrlGenerator
} from 'helpers/utils';


const classes:     ClassNamesProcessor = makeClassNamesProcessor(styles);
const cdnUrlAudio: CdnUrlGenerator     = makePrefixedCdnUrlGenerator('audio/');
const howlPlumbobPulse: Howl = new Howl({ src: [cdnUrlAudio('plumbob-pulse.mp3')] });

export interface ScreenQuestionConfig {
  translationKey: string;
  cdnDirectory:   string;
  assetA:         string;
  assetB:         string;
  imgQuestion:    string;
  screenAnswerA:  ScreenAnswer;
  screenAnswerB:  ScreenAnswer;
}

const animationDelay: { [key: string]: number } = {
  pictureA: 0.4,
  pictureB: 0.6,
  question: 0.8,
  answerA:  1.6,
  answerB:  2
};

const screenQuestionGenerator = (config: ScreenQuestionConfig): React.FC<PropsScreenWithAnswer> => {
  const cdnUrl: CdnUrlGenerator = makePrefixedCdnUrlGenerator(config.cdnDirectory);

  const ScreenQuestion: React.FC<PropsScreenWithAnswer> = (props: PropsScreenWithAnswer) => {
    const [isButtonClickedA, setIsButtonClickedA] = React.useState(false);
    const [isButtonClickedB, setIsButtonClickedB] = React.useState(false);
    const [isAnyButtonClicked, setIsAnyButtonClicked] = React.useState(false);
    const [pageReady, setPageReady] = React.useState(false);
    const { t } = useTranslation();

    const hidePlumbob = () => {
      if (!props.togglePlumbob) { return; }
      setTimeout(() => props.togglePlumbob(false), 1500);
    };

    const waitForAnimations = () => {
      const highestAnimationDelay = secsToMs(
        Math.max(...Object.keys(animationDelay).map((val) =>
          animationDelay[val] + 1))
      );

      window.setTimeout(() => {
        setPageReady(true);
      }, highestAnimationDelay);
    };

    React.useEffect(hidePlumbob, []);
    React.useEffect(waitForAnimations, []);

    function handleButtonClick(answer: ScreenAnswer) {
      howlPlumbobPulse.play();
      if (answer === config.screenAnswerA) {
        setIsButtonClickedA(true);
      }
      if (answer === config.screenAnswerB) {
        setIsButtonClickedB(true);
      }
      props.pulseQuestionPlumbob && props.pulseQuestionPlumbob();
      window.setTimeout(() => {
        setIsAnyButtonClicked(true);
      }, 600);
      window.setTimeout(() => props.screenCompleted(answer), 1000);

    }

    return (
      <div {...classes('screen-question')}>

        <div {...classes('c-question__textWrapper')}>
          <AnimationPopIn elementType="div" delay={animationDelay.question} damping={15} mass={1} stiffness={250}>
            <AnimationPopOut elementType="div" triggered={isAnyButtonClicked}>
              <div {...classes('c-question__text')}>
                <span {...classes('c-question__textInner')}>
                  {t(config.translationKey + '.question')}
                </span>
              </div>
            </AnimationPopOut>
          </AnimationPopIn>
        </div>

        <div {...classes('c-question')}>

          <div {...classes('c-answer')}>
            <figure {...classes('c-answer__figure c-answer__figure--a')}>
              <AnimationSlideOut elementType="div" slideOutTo="left" triggered={isAnyButtonClicked}>
                <AnimationSlideIn
                  elementType="div"
                  {...classes('c-answer__imageHolder c-answer__imageHolder--a')}
                  slideInFrom="left"
                  delay={animationDelay.pictureA}
                >
                  {pageReady && (
                    <QuestionVideo asset={config.assetA} cdnDirectory={config.cdnDirectory} />
                  )}
                  <img {...classes('c-answer__image')} src={`${cdnUrl(config.assetA)}.jpg`} alt="Pic A" />
                </AnimationSlideIn>
              </AnimationSlideOut>
            </figure>
            <footer {...classes('c-answer__footer')}>
              <div {...classes('c-answer__textWrapper')}>
                <AnimationJump elementType="div" triggered={isButtonClickedA}>
                  <AnimationSlideOut elementType="div" slideOutTo="left" triggered={isAnyButtonClicked}>
                    <AnimationPopIn
                      elementType="div"
                      delay={animationDelay.answerA}
                    >
                      <button {...classes('c-answer__button')} onClick={() => handleButtonClick(config.screenAnswerA)}>
                        {t(config.translationKey + '.answerA')}
                      </button>
                    </AnimationPopIn>
                  </AnimationSlideOut>
                </AnimationJump>
              </div>
            </footer>
          </div>

          <div {...classes('c-answer')}>
            <figure {...classes('c-answer__figure c-answer__figure--b')}>
              <AnimationSlideOut elementType="div" slideOutTo="right" triggered={isAnyButtonClicked}>
                <AnimationSlideIn
                  elementType="div"
                  {...classes('c-answer__imageHolder c-answer__imageHolder--b')}
                  slideInFrom="right"
                  delay={animationDelay.pictureB}
                >
                  {pageReady && (
                    <QuestionVideo asset={config.assetB} cdnDirectory={config.cdnDirectory} />
                  )}
                  <img {...classes('c-answer__image')} src={`${cdnUrl(config.assetB)}.jpg`} alt="Pic B" />
                </AnimationSlideIn>
              </AnimationSlideOut>
            </figure>
            <footer {...classes('c-answer__footer')}>
              <div {...classes('c-answer__textWrapper')}>
                <AnimationJump elementType="div" triggered={isButtonClickedB}>
                  <AnimationSlideOut elementType="div" slideOutTo="right" triggered={isAnyButtonClicked}>
                    <AnimationPopIn
                      elementType="div"
                      delay={animationDelay.answerB}
                    >
                      <button {...classes('c-answer__button c-answer__button--b')} onClick={() => handleButtonClick(config.screenAnswerB)}>
                        {t(config.translationKey + '.answerB')}
                      </button>
                    </AnimationPopIn>
                  </AnimationSlideOut>
                </AnimationJump>
              </div>
            </footer>
          </div>

        </div>

        <div {...classes('question-corner-top')}>
          <AnimationSlideIn elementType="div" slideInFrom="left" delay={2}>
            <AnimationSlideOut elementType="div" slideOutTo="left" triggered={isAnyButtonClicked}>
              <div {...classes('question-corner-top-thing')}></div>
            </AnimationSlideOut>
          </AnimationSlideIn>
        </div>
        <div {...classes('question-corner-bottom')}>
          <AnimationSlideIn elementType="div" slideInFrom="right" delay={2}>
            <AnimationSlideOut elementType="div" slideOutTo="right" triggered={isAnyButtonClicked}>
              <div {...classes('question-corner-bottom-thing')}>
                <span>{t(config.translationKey + '.step')}</span>
              </div>
            </AnimationSlideOut>
          </AnimationSlideIn>
        </div>
      </div>
    );

  };

  return ScreenQuestion;

};

export default screenQuestionGenerator;
