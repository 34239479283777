import React            from 'react';   // @see https://www.npmjs.com/package/react
import { Howler, Howl } from 'howler';  // @see https://www.npmjs.com/package/howler
import { useTranslation } from 'react-i18next';

import ScreenLoading           from 'components/screens/ScreenLoading/ScreenLoading';
import ScreenIntro             from 'components/screens/ScreenIntro/ScreenIntro';
import ScreenGeneratingResult  from 'components/screens/ScreenGeneratingResult/ScreenGeneratingResult';
import ScreenResult            from 'components/screens/ScreenResult/ScreenResult.js';

import 'i18n/init';
import styles from './App.module.scss';
import { determinePackFromAnswers } from 'game-logic';
import { cdnUrl, makeClassNamesProcessor, ClassNamesProcessor, getEnv } from 'helpers/utils';
import { parseReferrer } from 'helpers/referrer';
import { ScreenAnswer, Pack } from 'game-constants';

import {
  ScreenQ1, ScreenQ2, ScreenQ3, ScreenQ4, ScreenQ5, ScreenQ6
} from 'components/screens/screens-questions';
import AnimationSlideInVertical from 'components/animations/AnimationSlideInVertical';
import AnimationPulse from 'components/animations/AnimationPulse';
import AnimationPulseOut from 'components/animations/AnimationPulseOut';

const classes: ClassNamesProcessor = makeClassNamesProcessor(styles);

enum GameState { Loading, Intro, Q1, Q2, Q3, Q4, Q5, Q6, GeneratingR, Result }

const howlMainMusic:       Howl = new Howl({ src: [cdnUrl('audio/main-music.mp3')], loop: true });
const howlPlumbobSwoosh: Howl = new Howl({ src: [cdnUrl('audio/plumbob-swoosh.mp3')] });

const App: React.FC = () => {

  const [gameState, setGameState] = React.useState<GameState>(GameState.Loading);

  const [q1answer, setQ1answer] = React.useState<ScreenAnswer | null>(null);
  const [q2answer, setQ2answer] = React.useState<ScreenAnswer | null>(null);
  const [q3answer, setQ3answer] = React.useState<ScreenAnswer | null>(null);
  const [q4answer, setQ4answer] = React.useState<ScreenAnswer | null>(null);
  const [q5answer, setQ5answer] = React.useState<ScreenAnswer | null>(null);
  const [q6answer, setQ6answer] = React.useState<ScreenAnswer | null>(null);

  const [winningPack, setWinningPack] = React.useState<Pack | null>(null);
  const [plumbobOverlay, togglePlumbob] = React.useState(false);
  const [referrer, setReferrer] = React.useState<any>(null);
  const [pulseAnimation, setPulseAnimation] = React.useState<any>(false);
  const [pulseOutAnimation, setPulseOutAnimation] = React.useState<any>(false);

  const { t } = useTranslation();

  React.useEffect(() => setReferrer(parseReferrer()), []);

  const pulseQuestionPlumbob = () => {
    window.setTimeout(() => {
      setPulseAnimation(true);
    }, 600);
    window.setTimeout(() => {
      setPulseAnimation(false);
    }, 2500);
  };

  return (
    <div {...classes('app')}>
      <div className={t('cssClass.app')} style={{ height: '100%' }}>

        <div {...classes('background')} style={{ backgroundImage: `url(${cdnUrl('01-question-1/background.svg')})` }} />

        {(gameState === GameState.Loading || gameState === GameState.Intro) && (
          <div
            {...classes('background')}
            style={{ backgroundImage: `url(${cdnUrl('00-intro-screen/introBg.svg')})` }}
          />
        )}

        {plumbobOverlay && (
          <div {...classes('app-overlay')} style={{ backgroundImage: `url(${getEnv('REACT_APP_CDN_BASE_URL')}shared/plumbob_flying.gif)` }}/>
        )}

        {gameState === GameState.Loading && (
          <ScreenLoading screenCompleted={() => {
            togglePlumbob(true);
            setTimeout(() => {
              setGameState(GameState.Intro);
            }, 1400);
          }} />
        )}

        {gameState === GameState.Intro && (
          <ScreenIntro
            screenCompleted={() => {
              if (!howlMainMusic.playing()) {
                howlMainMusic.play();
              }
              togglePlumbob(true);
              howlPlumbobSwoosh.play();
              setTimeout(() => {
                setGameState(GameState.Q1);
              }, 1400);
            }}
            togglePlumbob={togglePlumbob}
            referrer={referrer}
          />
        )}

        {gameState === GameState.Q1 && (
          <ScreenQ1
            togglePlumbob={togglePlumbob}
            screenCompleted={(ans: ScreenAnswer) => {
              setQ1answer(ans); setGameState(GameState.Q2);
            }}
            pulseQuestionPlumbob={pulseQuestionPlumbob}
          />
        )}

        {gameState === GameState.Q2 && (
          <ScreenQ2
            screenCompleted={(ans: ScreenAnswer) => {
              setQ2answer(ans);
              setGameState(GameState.Q3);
            }}
            pulseQuestionPlumbob={pulseQuestionPlumbob}
          />
        )}

        {gameState === GameState.Q3 && (
          <ScreenQ3
            screenCompleted={(ans: ScreenAnswer) => {
              setQ3answer(ans);
              setGameState(GameState.Q4);
            }}
            pulseQuestionPlumbob={pulseQuestionPlumbob}
          />
        )}

        {gameState === GameState.Q4 && (
          <ScreenQ4
            screenCompleted={(ans: ScreenAnswer) => {
              setQ4answer(ans);
              setGameState(GameState.Q5);
            }}
            pulseQuestionPlumbob={pulseQuestionPlumbob}
          />
        )}

        {gameState === GameState.Q5 && (
          <ScreenQ5
            screenCompleted={(ans: ScreenAnswer) => {
              setQ5answer(ans);
              setGameState(GameState.Q6);
            }}
            pulseQuestionPlumbob={pulseQuestionPlumbob}
          />
        )}

        {gameState === GameState.Q6 && (
          <ScreenQ6
            screenCompleted={(ans: ScreenAnswer) => {
              setQ6answer(ans);
              setPulseOutAnimation(true);
              setTimeout(() => {
                togglePlumbob(true);
              }, 400);
              howlPlumbobSwoosh.play();
              setTimeout(() => {
                setGameState(GameState.GeneratingR);
              }, 1800);
            }}
          />
        )}

        {gameState === GameState.GeneratingR && q1answer && q2answer && q3answer && q4answer && q5answer && q6answer && (
          <ScreenGeneratingResult togglePlumbob={togglePlumbob} screenCompleted={() => {
            const winner: Pack = determinePackFromAnswers([
              q1answer, q2answer, q3answer, q4answer, q5answer, q6answer
            ]);
            setWinningPack(winner);
            togglePlumbob(true);
            howlPlumbobSwoosh.play();
            setTimeout(() => {
              setGameState(GameState.Result);
            }, 1400);
          }} />
        )}

        {gameState === GameState.Result && winningPack && (
          <ScreenResult togglePlumbob={togglePlumbob}
                        winningPack={winningPack}
                        muteBackgroundMusic={() => Howler.mute(true)}
                        unmuteBackgroundMusic={() => Howler.mute(false)}
                        referrer={referrer}
                        resetGame={() => {
                          togglePlumbob(true);
                          setTimeout(() => {
                            setGameState(GameState.Q1);
                          }, 1400);
                        }} />
        )}

        {gameState > 1 && gameState < 8 && (
          <div {...classes('c-question-plumbob__wrapper')}>
            <div {...classes('c-question-plumbob')}>
              <AnimationSlideInVertical elementType="div" slideInFrom="top" delay={1.5}>
                <AnimationPulse elementType="div" triggered={pulseAnimation} delay={1}>
                  <AnimationPulseOut elementType="div" triggered={pulseOutAnimation}>
                    <img src={`${getEnv('REACT_APP_CDN_BASE_URL')}shared/plumbob.gif`} alt="Spinning Sims Plumbob" />
                  </AnimationPulseOut>
                </AnimationPulse>
              </AnimationSlideInVertical>
            </div>
          </div>
        )}
      </div>
    </div>
  );

};

export default App;
