import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion


type XorY = 'x' | 'y';

// Smaller values will produce more jagged animation paths.
// Larger values will produce smoother animation paths.
const totalKeyframes: number = 32;

// @see https://en.wikipedia.org/wiki/Radian
const radiansCompleteRevolution: number = 2 * Math.PI;

// This function implements a "figure-of-eight" (infinity symbol) animation.
function generateKeyframe(type: XorY, size: number, step: number): number {

  // The trigonometric input angle is generated from the `step`.
  let angleRadians: number = radiansCompleteRevolution * (step / totalKeyframes);

  if (type === 'x') {

    let keyframe: number = size * Math.sin(angleRadians);

    // Make the X-motion move double the distance of the Y-motion
    // (otherwise the "figure-of-eight" shape would be squashed horizontally).
    keyframe *= 2;

    return keyframe;

  } else {  // type === 'y'

    // Make the Y-motion oscillate at twice the rate of the X-motion
    // (this creates the "figure-of-eight" shape).
    angleRadians *= 2;

    const keyframe: number = size * Math.sin(angleRadians);

    return keyframe;

  }

}

function generateKeyframes(type: XorY, size: number, timingOffset: number = 0): number[] {

  const keyframes: number[] = [];

  for (let step: number = 0; step <= totalKeyframes; step++) {

    const stepWithOffset: number = step + (timingOffset * totalKeyframes);

    keyframes.push(generateKeyframe(type, size, stepWithOffset));

  }

  return keyframes;

}

interface Props {
  elementType:         string;
  loopDuration:        number;
  movementAmount?:     number;
  timingOffset?:       number;
  children?:           React.ReactNode;
  [otherProp: string]: any;
}

const AnimationDance: React.FC<Props> = (props: Props) => {

  const {
    elementType,     // 'div', 'span', 'img', etc.
    loopDuration,    // Duration (in seconds) of the animation loop.
    movementAmount,  // Distance to move the animated element.
    timingOffset,    // Fraction (between 0.0 and 1.0) to offset the animation's timing.
    children,        // Children (for an element which can accept children, such as a <div>).
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  // If `movementAmount` isn't specified, then calculate `size` according to the window size.
  const size: number = movementAmount || (Math.min(window.innerWidth, window.innerHeight) / 1000);

  const allProps = {
    ...otherProps,
    transition: {             // @see https://www.framer.com/api/motion/animation/#transitions
      loop:     Infinity,     // @see https://www.framer.com/api/motion/types/#tween.loop
      ease:     'linear',     // @see https://www.framer.com/api/motion/types/#tween.ease
      duration: loopDuration  // @see https://www.framer.com/api/motion/types/#tween.duration
    },
    animate: {                // @see https://www.framer.com/api/motion/animation/#keyframes
      x: generateKeyframes('x', size, timingOffset),
      y: generateKeyframes('y', size, timingOffset)
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationDance;
