import React              from 'react';          // @see https://www.npmjs.com/package/react
import { useTranslation } from 'react-i18next';  // @see https://www.npmjs.com/package/react-i18next
//import FitText from '@kennethormandy/react-fittext'; // @see https://www.npmjs.com/package/@kennethormandy/react-fittext

import styles from './ScreenGeneratingResult.module.scss';
import { generatingResultDurationMs } from 'game-constants';
import { PropsScreenWithoutAnswer } from 'components/screens/screens-common';

import {
  CdnUrlGenerator,
  ClassNamesProcessor,
  makeClassNamesProcessor,
  makePrefixedCdnUrlGenerator
} from 'helpers/utils';


const classes: ClassNamesProcessor = makeClassNamesProcessor(styles);
const cdnUrl:  CdnUrlGenerator     = makePrefixedCdnUrlGenerator('07-gathering-results/');

const ScreenGeneratingResult: React.FC<PropsScreenWithoutAnswer> = (props: PropsScreenWithoutAnswer) => {

  const waitForVideo = () => { window.setTimeout(() => props.screenCompleted(), generatingResultDurationMs); };

  React.useEffect(waitForVideo, []);

  const { t } = useTranslation();

  const hidePlumbob = () => {
    if (!props.togglePlumbob) { return; }
    setTimeout(() => props.togglePlumbob(false), 1500);
  };

  React.useEffect(hidePlumbob, []);

  return (
    <div {...classes('screen-gathering-result')}>

      <video  {...classes('results__vid')} autoPlay loop muted playsInline>
        <source src={cdnUrl('gathering_results.webm')} />
        <source src={cdnUrl('gathering_results.mp4')} />
      </video>

      <img src={cdnUrl('07-gathering-results.jpg')} {...classes('results__img')} alt="dancing Sims characters" />

      <div {...classes('c-gathering__titleWrapper')}>
        <div {...classes('c-gathering__title')}>
          {t('screenGeneratingResult.title')}
        </div>
      </div>

      <div {...classes('c-gathering__subtitleWrapper')}>
        <div {...classes('c-gathering__subtitle')}>
          {t('screenGeneratingResult.subtitle')}
        </div>
      </div>

    </div>
  );

};

export default ScreenGeneratingResult;
