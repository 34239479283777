import React, { useState }              from 'react';              // @see https://www.npmjs.com/package/react
import { Howl }           from 'howler';             // @see https://www.npmjs.com/package/howler
import { useTranslation } from 'react-i18next';      // @see https://www.npmjs.com/package/react-i18next
import Slider from 'react-slick';

import { useMediaQuery } from 'react-responsive';
import { retrieveSocial } from '../../../helpers/referrer';
import styles from './ScreenIntro.module.scss';
import AnimationJump from 'components/animations/AnimationJump';
import AnimationPopIn from 'components/animations/AnimationPopIn';
import { PropsScreenWithoutAnswer } from 'components/screens/screens-common';
import { getLanguageFromUrlQueryString } from '../../../helpers/utils';

import {
  CdnUrlGenerator,
  ClassNamesProcessor,
  makeClassNamesProcessor,
  makePrefixedCdnUrlGenerator,
  getEnv
} from 'helpers/utils';

const classes:     ClassNamesProcessor = makeClassNamesProcessor(styles);
const cdnUrl:      CdnUrlGenerator     = makePrefixedCdnUrlGenerator('00-intro-screen/');
const cdnUrlAudio: CdnUrlGenerator     = makePrefixedCdnUrlGenerator('audio/');

const howlButtonClick: Howl = new Howl({ src: [cdnUrlAudio('takethequiz-playagain-buynow.mp3')] });

const ScreenIntro: React.FC<PropsScreenWithoutAnswer> = (props: PropsScreenWithoutAnswer) => {
  const [sliderOne, setSliderOne] = useState<any>(null);
  const [sliderTwo, setSliderTwo] = useState<any>(null);

  const [isButtonClicked, setIsButtonClicked] = React.useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { t } = useTranslation();

  function handleButtonClick() {
    howlButtonClick.play();
    setIsButtonClicked(true);

    window.setTimeout(() => {
      props.screenCompleted();
    }, 1400);
  }

  const hidePlumbob = () => {
    if (!props.togglePlumbob) { return; }
    setTimeout(() => props.togglePlumbob(false), 1500);
  };

  React.useEffect(hidePlumbob, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: -1,
    vertical: true,
    autoplay: true,
    arrows: false,
    rtl: false,
    swipe: false,
    initialSlide: 7,
    afterChange: (index: any) => {
      if (index === 0) {
        sliderOne.slickPause();
        sliderTwo.slickPause();
      }
    }
  };

  const lang = getLanguageFromUrlQueryString();
  const simsLogo = () => {
    if (lang === 'es') {
      return 'sims4Logo-es.svg';
    }
    if (lang === 'de') {
      return 'sims4Logo-de.svg';
    }
    return 'sims4Logo.svg';
  };

  const retailer = () => {
    const { referrer } = props;
    if (!referrer) {
      return null;
    }

    const socialData = retrieveSocial(referrer);
    if (!socialData || !socialData.intro) {
      return null;
    }

    return (
      <AnimationPopIn elementType="div" delay={1.75} damping={15} mass={1} stiffness={250} {...classes('retailer-wrapper')}>
        <img {...classes('retailer-logo')} src={`${getEnv('REACT_APP_CDN_BASE_URL')}retailer/${socialData.retailerKey}.png`} alt={`${socialData.retailerKey} logo`} />
      </AnimationPopIn>
    );
  };

  const slide = (image: String, slideType: String) => {
    return (
      <div>
        <picture>
          <img alt={`${image} Sim`} src={`${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/${slideType}/${image}.png`} />
        </picture>
      </div>
    );
  };

  return (
    <div {...classes('screen-intro')}>

      <div {...classes('intro-contentWrapper')}>

        {!isTabletOrMobile && (
          <div {...classes('intro-slider', 'intro-slider--left')}>
            <div className="intro-slider-left">
              <Slider
                {...sliderSettings}
                ref={(slider) => (setSliderOne(slider))}
                asNavFor={sliderTwo}
              >
                {slide('vampire', 'left')}
                {slide('dancer', 'left')}
                {slide('catlover', 'left')}
                {slide('witch', 'left')}
                {slide('doctor', 'left')}
                {slide('summerman', 'left')}
                {slide('agent', 'left')}
                {slide('fashionlad', 'left')}
              </Slider>
            </div>
          </div>
        )}

        <div
          {...classes('intro-content')}
          style={{
            justifyContent: retailer() ? 'space-between' : 'space-around',
            paddingBottom: retailer() ? '3rem' : 0
          }}
        >
          {retailer()}
          <div {...classes('logo')}>
            <picture>
              <source
                srcSet={`${getEnv('REACT_APP_CDN_BASE_URL')}${simsLogo()}`}
                media="(min-width: 1024px)" type="image/jpeg"
              />
              <AnimationPopIn
                elementType="div"
                delay={0.5}
                damping={4}
                mass={0.5}
                stiffness={50}
              >
                <img {...classes('intro-logo')} src={`${getEnv('REACT_APP_CDN_BASE_URL')}${simsLogo()}`} alt="logo" />
              </AnimationPopIn>
            </picture>
          </div>

          <h1 {...classes('c-title')}>
            <AnimationPopIn elementType="div" delay={1.75} damping={15} mass={1} stiffness={250} alt="logo">
              {t('screenIntro.whichSim')}
            </AnimationPopIn>
          </h1>

          <div {...classes('c-button')}>
            <AnimationPopIn elementType="div" delay={3} damping={15} mass={1} stiffness={250}>
              <AnimationJump elementType="div" triggered={isButtonClicked}>
                <button onClick={handleButtonClick}>
                  {t('screenIntro.playNow')}
                </button>
              </AnimationJump>
            </AnimationPopIn>
          </div>
        </div>

        {!isMobile && (
          <div {...classes('intro-slider', 'intro-slider--right')}>
            <div className="intro-slider-right">
              <Slider
                {...sliderSettings}
                autoplay={false}
                ref={(slider) => (setSliderTwo(slider))}
                asNavFor={sliderOne}
              >
                {slide('Bowler', 'right')}
                {slide('UniGirl', 'right')}
                {slide('Vampiress', 'right')}
                {slide('Mother', 'right')}
                {slide('Scientist', 'right')}
                {slide('FitnessFreak', 'right')}
                {slide('Basketball', 'right')}
                {slide('SummerGirl', 'right')}
              </Slider>
            </div>
          </div>
        )}
      </div>


      <div {...classes('footer')}>
        <div {...classes('footer__edge')} />
        <div {...classes('footer__textWrapper')}>
          <p {...classes('footer__text')}>
            {t('screenIntro.copyright')}
          </p>
        </div>
        <div {...classes('footer__edge footer__logos')}>
          <img {...classes('footer__logo')} src={`${cdnUrl('maxis-logo.svg')}`} alt="logo 1"/>
          <img {...classes('footer__logo')} src={`${cdnUrl('ea-logo.svg')}`} alt="logo 2"/>
        </div>
      </div>

      <div
        {...classes('background')}
        style={{ backgroundImage: `url(${cdnUrl('introBg.svg')})` }}
      />

    </div>
  );

};

export default ScreenIntro;
