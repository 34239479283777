import {
  getEnv
} from 'helpers/utils';

const imageSrcs = [
  `${getEnv('REACT_APP_CDN_BASE_URL')}shared/plumbob_flying.gif`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/agent.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/catlover.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/dancer.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/doctor.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/fashionlad.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/summerman.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/vampire.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/left/witch.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/Basketball.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/Bowler.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/FitnessFreak.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/Mother.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/Scientist.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/SummerGirl.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}00-intro-screen/right/UniGirl.png`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}01-question-1/Q1_BUSY.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}01-question-1/Q1_SCENIC.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}02-question-2/Q2_FRIENDSHIPS.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}02-question-2/Q2_FORTUNE.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}03-question-3/Q3_GENIUS.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}03-question-3/Q3_CREATIVE.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}04-question-4/Q4_PETTING.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}04-question-4/Q4_PARTYING.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}05-question-5/Q5_FAMILIAR.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}05-question-5/Q5_OTHER_WORLDLY.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}06-question-6/Q6_BEDROOM.jpg`,
  `${getEnv('REACT_APP_CDN_BASE_URL')}06-question-6/Q6_DARING.jpg`
];

const preloadImages = () => {
  imageSrcs.forEach((src) => {
    const image = new Image();
    image.src = src;
  });
};

export default preloadImages;
