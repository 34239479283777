import React from 'react';
import { retrieveSocial } from '../../helpers/referrer';
import { getEnv } from 'helpers/utils';

const SocialLinks = (props) => {
  const { referrer } = props;
  if (!referrer) {
    return null;
  }

  const socialData = retrieveSocial(referrer);
  if (!socialData || !socialData.result) {
    return null;
  }

  const { resultsCopy } = socialData;
  const socialKeys = ['facebook', 'twitter', 'instagram'].filter((key) => socialData[key]);

  return (
    <div className="c-socialLinks">
      <div className="c-socialLinks__row">
        <img src={`${getEnv('REACT_APP_CDN_BASE_URL')}retailer/${socialData.retailerKey}.png`} alt={`${socialData.retailerKey} logo`} className="c-socialLinks__logo" />
        <div className="c-socialLinks__links">
          {socialKeys.map((key) => (
            <a href={socialData[key]} key={key} target="_blank">
              <img
                src={`${getEnv('REACT_APP_CDN_BASE_URL')}shared/${key}.svg`}
                alt={`Link to ${key}`}
                className="c-socialLinks__socialImage"
              />
            </a>
          ))}
        </div>
      </div>
      {resultsCopy && <span className="c-socialLinks__copy">{resultsCopy}</span>}
    </div>
  );
};

export default SocialLinks;
