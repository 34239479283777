import SocialManifest from './socialManifest';

// const testData = [
//   'https://www.google.com/',
//   'https://www.bbc.co.uk/news/entertainment-arts-51488884',
//   'https://paid.outbrain.com/network/redir?p=i4ySwDxeRkJwvXJQxBbs39dbcp1n8fqgMKH-qxhehw6aIWM6551jx8FMBG97sLVV8gbsCvOAxK0A65XjlVFd8pzvt7CazjtnUmlgszoiAhbZNmEBTGU74wARQMBJxLcZu--TgsS49nudcjWdc4jnKUNXBE3sK0d06Pbac6BzbUsNR7Rds9otZeBJAm2pBHCa7i6QDpts0HkgRwCLAquoITBB9ehJ5T-FBQh20PXa5BQafrOyqhDqUtK-NEcWJsORkvWaEea0TFeVl99c2p4OMRGk0-f2-Qt9jUssoS4MsFlPRigYg7HZGMqEkZxnG-dc_',
//   'http://www.mueller.de',
//   'http://www.fnac.es',
//   'http://www.ea.es',
//   'http://www.mediamarkt.es',
//   'http://www.game.es'
// ];

const referrerLookup = {
  'muellerde': 'www.mueller.de',
  'fnaces': 'www.fnac.es',
  'eaes': 'www.ea.es',
  'mediamarktes': 'www.mediamarkt.es',
  'gamees': 'www.game.es',
  'gamestop': 'www.gamestop.com',
  'bestbuy': 'www.bestbuy.com'
};

const parseReferrer = () => {
  const { referrer } = document;

  if (referrer.indexOf('ea.com/es-es') !== -1) {
    return referrerLookup.eaes;
  }

  const currentUrl = new URL(document.location);
  if (currentUrl.searchParams.get('referrer')) {
    const key = currentUrl.searchParams.get('referrer');
    return referrerLookup[key];
  }

  if (!referrer) {
    return false;
  }

  const url = new URL(referrer);
  return url.host;
};

const retrieveSocial = (referrer) => {
  const manifestEntries = Object.keys(SocialManifest);
  if (!manifestEntries.includes(referrer)) {
    return false;
  }

  return SocialManifest[referrer];
};

const retrieveBuyNow = (referrer, pack) => {
  const manifestEntries = Object.keys(SocialManifest);
  if (!manifestEntries.includes(referrer)) {
    return false;
  }

  return SocialManifest[referrer].buyNow[pack];
};

export { parseReferrer, retrieveSocial, retrieveBuyNow };
