import i18next              from 'i18next';        // @see https://www.npmjs.com/package/i18next
import { initReactI18next } from 'react-i18next';  // @see https://www.npmjs.com/package/react-i18next

import { getLanguageFromUrlQueryString } from 'helpers/utils';

import en from 'i18n/en.json';
import es from 'i18n/es.json';
import de from 'i18n/de.json';


// @see https://www.i18next.com/overview/configuration-options
const options: i18next.InitOptions = {
  resources:   { en, es, de },
  defaultNS:   'the-sims-4',
  lng:         getLanguageFromUrlQueryString(),
  fallbackLng: 'en'  // Fallback is English
};

// @see https://react.i18next.com/guides/quick-start#configure-i-18-next
i18next
  .use(initReactI18next)
  .init(options);
