import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';      // @see https://www.npmjs.com/package/react-i18next
import AnimationSlideInVertical from 'components/animations/AnimationSlideInVertical';
import AnimationSlideOutVertical from 'components/animations/AnimationSlideOutVertical';
import styles from './ScreenLoading.module.scss';

import {
  ClassNamesProcessor,
  makeClassNamesProcessor,
  getEnv
} from 'helpers/utils';
import preloadImages from 'helpers/preload';

const classes:     ClassNamesProcessor = makeClassNamesProcessor(styles);

const ScreenLoading = (props: any) => {
  const [plumbobReady, setPlumbobReady] = useState(false);
  const [loadingDone, setLoadingDone] = useState(false);
  const { t } = useTranslation();

  const plumbobSrc = `${getEnv('REACT_APP_CDN_BASE_URL')}shared/plumbob.gif`;
  const preloadPlumbob = () => {
    const plumbobImage = new Image();
    plumbobImage.addEventListener('load', () => {
      setPlumbobReady(true);
    });
    plumbobImage.src = plumbobSrc;
  };

  const setTimers = () => {
    window.setTimeout(() => {
      setLoadingDone(true);
    }, 5000);
    window.setTimeout(() => {
      props.screenCompleted();
    }, 5500);
  };

  useEffect(preloadPlumbob, []);
  useEffect(preloadImages, [plumbobReady]);
  useEffect(setTimers, []);

  return (
    <div {...classes('screen-loading')}>
      <div {...classes('c-loading__content')}>
        <div {...classes('c-loading__plumbob')}>
          {plumbobReady && <AnimationSlideInVertical elementType="div" slideInFrom="top">
            <AnimationSlideOutVertical elementType="div" slideOutFrom="top" triggered={loadingDone}>
              <div {...classes('c-loading__plumbobInner')}>
                <img src={plumbobSrc} alt="Spinning Sims Plumbob" />
              </div>
            </AnimationSlideOutVertical>
          </AnimationSlideInVertical>}
        </div>
        <AnimationSlideOutVertical elementType="div" slideOutFrom="bottom" triggered={loadingDone}>
          <h1 {...classes('c-loading__text')}>
            {t('screenLoading.message')}
            <div {...classes('c-loading__text-one')}>.</div>
            <div {...classes('c-loading__text-two')}>.</div>
            <div {...classes('c-loading__text-three')}>.</div>
          </h1>
        </AnimationSlideOutVertical>
      </div>
    </div>
  );
};

export default ScreenLoading;
