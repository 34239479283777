import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageFromUrlQueryString } from '../../../helpers/utils';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { retrieveSocial, retrieveBuyNow } from '../../../helpers/referrer';
import SocialLinks from '../../socialLinks';
import AnimationSlideInVertical from 'components/animations/AnimationSlideInVertical';
import AnimationDance from 'components/animations/AnimationDance';

import {
  makePrefixedCdnUrlGenerator,
  getEnv
} from 'helpers/utils';

const cdnUrl = makePrefixedCdnUrlGenerator('08-results/');

const ScreenResult = (props) => {
  const { t } = useTranslation();
  const lang = getLanguageFromUrlQueryString();
  const { winningPack } = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  // State & refs
  const [contentSlider, setContentSlider] = useState(null);
  const contentSlideEl = useRef(null);
  const contentArea = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(null);

  const contentSliderSettings = {
    dotsClass: 'slick-dots',
    dots: false,
    infinite: true,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    swipe: false,
    initialSlide: 0,
    afterChange: () => {
      // Save current slide to statement
      setPrevSlide(currentSlide);
      window.setTimeout(() => {
        setCurrentSlide(contentSlider.innerSlider.state.currentSlide);
        const initial = document.querySelector('.initial-0');
        initial && initial.classList.remove('initial-0');
      }, 750);
    }
  };

  const ratingImg = () => {
    const localisedRating = {
      de: 'rating-de.png',
      es: 'rating-es.png'
    };

    if (!localisedRating[lang]) {
      return 'rating.png';
    }

    return localisedRating[lang];
  };

  // Methods
  const buyNowHref = () => {
    const query = {
      de: '?setLocale=de-de',
      es: '?setLocale=es-es'
    };
    const langQuery = query[lang] ? query[lang] : '';
    const defaultUrl = `https://www.ea.com/games/the-sims/the-sims-4${langQuery}`;
    const { referrer } = props;

    if (!referrer) {
      return defaultUrl;
    }

    const referrerUrl = retrieveBuyNow(referrer, winningPack.packs[currentSlide]);
    if (!referrerUrl) {
      return defaultUrl;
    }

    return referrerUrl;
  };

  const handleStartOverClick = () => {
    props.resetGame();
  };

  const hidePlumbob = () => {
    if (!props.togglePlumbob) { return; }
    setTimeout(() => props.togglePlumbob(false), 1500);
  };

  const handleNavClick = (goTo) => {

    contentSlider && contentSlider.slickGoTo(goTo);
  };

  const setColourForSlide = () => {
    const colours = [
      '#044040',
      '#0574FF',
      '#114E02'
    ];

    return colours[currentSlide] ? colours[currentSlide] : '#044040';
  };

  // Effects
  useEffect(hidePlumbob, []);

  const positionResultRating = () => {
    const { referrer } = props;

    if (!referrer) {
      return '';
    }

    const referrerData = retrieveSocial(referrer);
    if (!referrerData) {
      return '';
    }

    return referrerData.result ? 'result-rating--bottomPadding' : '';
  };

  // Shared markup
  // Buttons container
  const resultButtons = () => (
    <div className="result-buttons-container">
      <div className="result-buttonContainer">
        <AnimationDance elementType="div" loopDuration={2}>
          <a href={buyNowHref()} className={`button result-button result-button--${currentSlide}`} target="_blank" style={{ color: setColourForSlide() }} onClick={() => {
            window.gtag('event', 'buyNow', {
              'event_category': 'results'
            });
          }}>
            {t('screenResult.buyNow')}
          </a>
        </AnimationDance>
      </div>
      <div className="result-startOverContainer">
        <button className="result-startOver" onClick={handleStartOverClick}>
          <div className="result-startOverInner">
            <span>{t('screenResult.startOver')}</span>
            <img className="result-startOverIcon" src={cdnUrl('start-over.svg')} alt="" />
          </div>
        </button>
      </div>
      {isMobile && <SocialLinks referrer={props.referrer} />}
    </div>
  );

  // ---------------------------//
  // Sliders Setup //
  // ---------------------------//

  // Slide building methods
  const packSlide = (image, pack, key, isActive, isPrev) => (
    <div className={`pack-slide ${isActive ? 'is-active' : ''} ${isPrev ? 'is-previous is-animating' : ''} ${key === 2 ? 'initial-0' : ''}`} key={key}>
      <img alt={`${image} Pack`} src={`${getEnv('REACT_APP_CDN_BASE_URL')}08-results/${pack}/${lang}/${image}_pack.png`} />
    </div>
  );

  const disclaimer = (pack) => {
    const customDisclaimer = t(`packs.${winningPack.name}.${pack}.disclaimer`);
    return customDisclaimer || t('screenResult.requiresSims');
  };

  const contentSlide = (subPack) => {
    return (
      <div className="result-contentSlide" key={`${subPack}_key`} ref={ contentSlideEl }>
        <div className="result-contentInner">

          <div className="result-copyContainer" ref={ contentArea }>
            <ul className="result-slick-dots">
              {[0, 1, 2].map((val, i) => (
                <li
                  key={`dot-${i}`}
                  className={currentSlide === val ? 'result-slick-dot is-active' : 'result-slick-dot'}
                  onClick={() => handleNavClick(val)}
                />
              ))}
            </ul>
            <h1 className="result-header">
              <AnimationDance elementType="div" loopDuration={3}>
                <div className="result-headerInner">
                  {t(`packs.${winningPack.name}.${subPack}.title`)}
                </div>
              </AnimationDance>
            </h1>

            <div className="result-copyWrapper">
              <h3 className="result-copy">
                {t(`packs.${winningPack.name}.${subPack}.description`)}
              </h3>
              <h6 className="result-disclaimer">{disclaimer(subPack)}</h6>
            </div>
          </div>

          {!isTabletOrMobile && resultButtons()}

        </div>

        <div className="result-character">
          <div className="result-character-inner">
            <img alt={`${winningPack.name} Sim`} src={`${getEnv('REACT_APP_CDN_BASE_URL')}08-results/${winningPack.name}/${subPack}.png`} />
          </div>
        </div>
      </div>
    );
  };

  // Output pack slides method
  const outputPackSlides = () => {
    if (!winningPack.packs) { return null; }
    return (
      winningPack.packs.map((pack, i) => {
        const isActive = i === currentSlide;
        const isPrevious = i === prevSlide;

        return packSlide(pack, winningPack.name, i, isActive, isPrevious);
      })
    );
  };

  // ---------------------------//
  // End Sliders Setup //
  // ---------------------------//

  const packsTopStyles = contentArea.current && isTabletOrMobile ? { top: contentArea.current.clientHeight } : {};

  return (
    <div className="result-screen">

      <div
        className="result-screen-background"
        style={{
          backgroundImage: `url(${cdnUrl('background-1.svg')})`,
          opacity: currentSlide === 0 ? 1 : 0
        }}
      />
      <div
        className="result-screen-background"
        style={{
          backgroundImage: `url(${cdnUrl('background-2.svg')})`,
          opacity: currentSlide === 1 ? 1 : 0
        }}
      />
      <div
        className="result-screen-background"
        style={{
          backgroundImage: `url(${cdnUrl('background-3.svg')})`,
          opacity: currentSlide === 2 ? 1 : 0
        }}
      />

      <div className="result-triangleContainer">
        <div className="result-triangle">
          <AnimationSlideInVertical
            elementType="div"
            slideInFrom="top"
            delay={0.5}
            damping={16}
            stiffness={160}
            mass={1}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1300 1428">
              <path
                fill={setColourForSlide()}
                fillRule="evenodd"
                d="M0 374.574L1299.523.235 668.52 1427.184z"
              />
            </svg>
          </AnimationSlideInVertical>
        </div>
      </div>

      <div className="result-packs"  style={packsTopStyles}>
        <div className="result-pack-slider js-resultPacks">
          {outputPackSlides()}
        </div>
        {isTabletOrMobile && resultButtons()}
      </div>

      <div className="result-content">
        <Slider
          {...contentSliderSettings}
          ref={(slider) => setContentSlider(slider)}
        >
          {winningPack.packs.map((subPack) => contentSlide(subPack))}
        </Slider>
      </div>

      <div className={`result-rating ${positionResultRating()}`}>
        <img src={cdnUrl(ratingImg())} alt="" />
      </div>

      {!isMobile && <SocialLinks referrer={props.referrer} />}
    </div>
  );

};

export default ScreenResult;
