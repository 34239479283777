export enum ScreenAnswer {

  Q1BusyLively = 1,
  Q1QuaintScenic,

  Q2LastingFriendships,
  Q2LastingFortune,

  Q3IntellectualGenius,
  Q3CreativeMaster,

  Q4PettingAnAnimal,
  Q4PartyingLikeAnAnimal,

  Q5FamiliarBeings,
  Q5OtherWorldlyCompanions,

  Q6NewRecipes,
  Q6Classics,
}

export enum Pack {
  DiscoverUniversity = 1,
  CityLiving,
  GetFamous,
  GetToWork,
  IslandLiving,
  Seasons,
  CatsAndDogs,
  GetTogether
}

export const Packs: Pack[] = [
  Pack.DiscoverUniversity,
  Pack.CityLiving,
  Pack.GetFamous,
  Pack.GetToWork,
  Pack.IslandLiving,
  Pack.Seasons,
  Pack.CatsAndDogs,
  Pack.GetTogether
];

// Duration of the "Generating Result" screen (milliseconds)
export const generatingResultDurationMs: number = 10000;
