import { debugLog } from 'helpers/utils';

import {
  ScreenAnswer as Answer,
  Pack
} from 'game-constants';


const answerPacks: { [answer: number]: Pack[] } = {

  [Answer.Q1BusyLively]: [
    Pack.DiscoverUniversity,
    Pack.CityLiving,
    Pack.GetFamous,
    Pack.GetToWork
  ],
  [Answer.Q1QuaintScenic]: [
    Pack.IslandLiving,
    Pack.Seasons,
    Pack.CatsAndDogs,
    Pack.GetTogether
  ],

  [Answer.Q2LastingFriendships]: [
    Pack.IslandLiving,
    Pack.Seasons,
    Pack.CatsAndDogs,
    Pack.GetTogether
  ],
  [Answer.Q2LastingFortune]: [
    Pack.DiscoverUniversity,
    Pack.CityLiving,
    Pack.GetFamous,
    Pack.GetToWork
  ],

  [Answer.Q3IntellectualGenius]: [
    Pack.DiscoverUniversity,
    Pack.GetToWork,
    Pack.IslandLiving,
    Pack.CatsAndDogs
  ],
  [Answer.Q3CreativeMaster]: [
    Pack.CityLiving,
    Pack.GetFamous,
    Pack.Seasons,
    Pack.GetTogether
  ],

  [Answer.Q4PettingAnAnimal]: [
    Pack.GetToWork,
    Pack.IslandLiving,
    Pack.CatsAndDogs
  ],
  [Answer.Q4PartyingLikeAnAnimal]: [
    Pack.DiscoverUniversity,
    Pack.CityLiving,
    Pack.GetFamous,
    Pack.Seasons,
    Pack.GetTogether
  ],

  [Answer.Q5FamiliarBeings]: [
    Pack.CityLiving,
    Pack.GetFamous,
    Pack.CatsAndDogs,
    Pack.GetTogether
  ],
  [Answer.Q5OtherWorldlyCompanions]: [
    Pack.DiscoverUniversity,
    Pack.GetToWork,
    Pack.IslandLiving,
    Pack.Seasons
  ],

  [Answer.Q6NewRecipes]:  [
    Pack.CityLiving,
    Pack.IslandLiving,
    Pack.Seasons,
    Pack.CatsAndDogs
  ],
  [Answer.Q6Classics]: [
    Pack.DiscoverUniversity,
    Pack.GetFamous,
    Pack.GetTogether,
    Pack.GetToWork
  ]

};

const allPacks: Pack[] = [
  Pack.DiscoverUniversity,
  Pack.CityLiving,
  Pack.GetFamous,
  Pack.GetToWork,
  Pack.IslandLiving,
  Pack.Seasons,
  Pack.CatsAndDogs,
  Pack.GetTogether
];

export function determinePackFromAnswers(answers: Answer[]): Pack {

  debugLog('Answers:', answers.map((ans: Answer): string => Answer[ans]));

  const packScores: { [pack: number]: number } = {
    [Pack.DiscoverUniversity]: 0,
    [Pack.CityLiving]: 0,
    [Pack.GetFamous]: 0,
    [Pack.GetToWork]: 0,
    [Pack.IslandLiving]: 0,
    [Pack.Seasons]: 0,
    [Pack.CatsAndDogs]: 0,
    [Pack.GetTogether]: 0
  };

  let highestScore: number = 0;

  for (const answer of answers) {
    const packs: Pack[] = answerPacks[answer];
    for (const pack of packs) {
      packScores[pack]++;
      highestScore = Math.max(highestScore, packScores[pack]);
    }
  }

  debugLog('Pack scores:');
  for (const packId of Object.keys(packScores)) {
    // @ts-ignore
    debugLog('  ' + packScores[packId] + ' for ' + Pack[packId]);
  }

  debugLog('Highest score:', highestScore);

  const highestScoringPacks: Pack[] = allPacks.filter(
    (char: Pack): boolean => (packScores[char] === highestScore)
  );

  debugLog(
    'Highest-scoring packs:',
    highestScoringPacks.map((packId: number): string => Pack[packId])
  );

  let winningPack: Pack = highestScoringPacks[0];

  if (highestScoringPacks.length > 1) {

    // let filteredCharacters: Pack[] = [];

    // if (answers.includes(Answer.Q7Sunseeker)) {
    //   filteredCharacters = highestScoringCharacters.filter((char: Pack): boolean => PlantCharacters.includes(char));
    // } else {  // If the user didn't answer Q7Sunseeker, then they must have answered Q7ShadeBaby.
    //   filteredCharacters = highestScoringCharacters.filter((char: Pack): boolean => ZombieCharacters.includes(char));
    // }

    // const randomCharacterIndex: number = Math.floor(Math.random() * filteredCharacters.length);

    // winningCharacter = filteredCharacters[randomCharacterIndex];

  }

  debugLog('Winning pack:', Pack[winningPack]);

  if (!winningPack) {
    winningPack = allPacks[1];
  }

  const resultsData: any = {
    '1': {
      name: 'discoverUniversity',
      packs: [
        'discoverUniversity',
        'realmOfMagic',
        'tinyLiving'
      ]
    },
    '2': {
      name: 'cityLiving',
      packs: [
        'cityLiving',
        'dineOut',
        'moschino'
      ]
    },
    '3': {
      name: 'getFamous',
      packs: [
        'getFamous',
        'spaDay',
        'vintageGlamour'
      ]
    },
    '4': {
      name: 'getToWork',
      packs: [
        'getToWork',
        'strangeville',
        'fitness'
      ]
    },
    '5': {
      name: 'islandLiving',
      packs: [
        'islandLiving',
        'jungleAdventures',
        'romanticGarden'
      ]
    },
    '6': {
      name: 'seasons',
      packs: [
        'seasons',
        'parenthood',
        'toddler'
      ]
    },
    '7': {
      name: 'catsAndDogs',
      packs: [
        'catsAndDogs',
        'outdoorRetreat',
        'myFirstPet'
      ]
    },
    '8': {
      name: 'getTogether',
      packs: [
        'getTogether',
        'vampires',
        'bowlingNight'
      ]
    }
  };

  return resultsData[winningPack];
}
