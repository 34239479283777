import classNamesLib from 'classnames';  // @see https://www.npmjs.com/package/classnames


/**
 * A map generated by CSS Modules, where
 * the keys   are the source CSS              class names, and
 * the values are the CSS Modules transformed class names.
 */
type CssModulesClassesMap = { [key: string]: string };

// @see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/cf25895/types/classnames/types.d.ts#L9
type ClassDefinition = string | { [key: string]: boolean };

// @see https://reactjs.org/docs/dom-elements.html#classname
type ClassNameReactProp = { className: string };

// @see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/cf25895/types/classnames/types.d.ts#L11
export type ClassNamesProcessor = (...classes: ClassDefinition[]) => ClassNameReactProp;

/**
 * The `ClassNamesProcessor` function's parameters can be anything supported by the `classnames` NPM library.
 *
 * Example usage of `makeClassNamesProcessor()` for a React component:
 *
 *     import styles from './MyComponent.module.scss';
 *
 *     const classes: ClassNamesProcessor = makeClassNamesProcessor(styles);
 *
 *     const MyComponent = (props) => <div {...classes('my-css-class', { 'my-optional-class': true })}>;
 */
export function makeClassNamesProcessor(cssModulesClassesMap: CssModulesClassesMap): ClassNamesProcessor {

  const classNamesProcessor: ClassNamesProcessor =
    function (...classDefinitions: ClassDefinition[]): ClassNameReactProp {

      const processedClassNames: string = classNamesLib(classDefinitions)
        .split(' ')
        .map((key: string): string => cssModulesClassesMap[key])
        .join(' ');

      const classNameReactProp: ClassNameReactProp = { className: processedClassNames };

      return classNameReactProp;

    };

  return classNamesProcessor;

}

/**
 * Gets the specified environment variable.
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables
 * @see /.env
 */
export function getEnv(environmentVariable: string): string {

  return (process.env[environmentVariable] as string);

}

/**
 * Logs the specified details to the console (if debug logging is enabled).
 */
export function debugLog(...details: any) {

  if (getEnv('REACT_APP_ENABLE_DEBUG_LOG') === 'true') {
    console.log(...details);  // eslint-disable-line no-console
  }

}

export type CdnUrlGenerator = (relativePath: string) => string;

/**
 * Converts a relative path (eg: "my-directory/my-image.png") to an absolute
 * URL (eg: "https://s3.amazonaws.com/my-bucket/my-directory/my-image.png")
 * using the configured CDN's base URL.
 */
export const cdnUrl: CdnUrlGenerator = (relativePath: string): string => {

  return getEnv('REACT_APP_CDN_BASE_URL') + relativePath;

};

/**
 * Creates a `CdnUrlGenerator` using the specified `prefix`.
 * Useful for creating a `CdnUrlGenerator` for a specific directory.
 */
export function makePrefixedCdnUrlGenerator(prefix: string): CdnUrlGenerator {

  const cdnUrlGenerator: CdnUrlGenerator = function (relativePath: string): string {
    return cdnUrl(prefix + relativePath);
  };

  return cdnUrlGenerator;

}

/**
 * Converts the specified time value from seconds to milliseconds.
 */
export function secsToMs(seconds: number): number {

  const MILLISECS_PER_SEC: number = 1000;

  return seconds * MILLISECS_PER_SEC;

}

const queryStringLanguageRegex: RegExp = /lang=([a-z]{2}(?:-[a-z]{2})?)/;

/**
 * Get the language parameter from the query string.
 */
export function getLanguageFromUrlQueryString(): string {

  const languageMatch: RegExpMatchArray | null = window.location.search.match(queryStringLanguageRegex);

  if (languageMatch) {
    return languageMatch[1];
  } else {
    return 'en';  // Default is English
  }

}
