import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion

import { numericField } from 'components/animations/utils';


type SlideOutTo = 'left' | 'right';

interface Props {
  elementType:         string;
  children?:           React.ReactNode;
  slideOutTo:          SlideOutTo;
  triggered:           boolean;
  duration?:           number;
  delay?:              number;
  [otherProp: string]: any;
}

const AnimationSlideOut: React.FC<Props> = (props: Props) => {

  const {
    elementType,  // 'div', 'span', 'img', etc.
    children,     // Children (for an element which can accept children, such as a <div>).
    slideOutTo,   // Which side to slide the element out to ('left' or 'right').
    triggered,    // Set this to `false` initially. Change to `true` to trigger the animation.
    delay,        // @see https://www.framer.com/api/motion/types/#orchestration.delay
    duration,     // Duration (in seconds) of the animation.
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  let x: number = 0;

  if (triggered) {
    x = (slideOutTo === 'left' ? -window.innerWidth : window.innerWidth);
  }

  const allProps = {
    ...otherProps,
    animate:    { x },                       // @see https://www.framer.com/api/motion/animation/#target-object
    transition: {
      delay: numericField(delay, 0),                 // @see https://www.framer.com/api/motion/animation/#transitions
      duration: numericField(duration, 0.8)  // @see https://www.framer.com/api/motion/types/#tween.duration
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationSlideOut;
