import React, { useRef } from 'react';
import styles from './ScreenQuestion.module.scss';

import {
  CdnUrlGenerator,
  ClassNamesProcessor,
  makeClassNamesProcessor,
  makePrefixedCdnUrlGenerator
} from 'helpers/utils';

const classes: ClassNamesProcessor = makeClassNamesProcessor(styles);

const QuestionVideo = (props: any) => {
  const video = useRef<any>(null);

  const cdnUrl: CdnUrlGenerator = makePrefixedCdnUrlGenerator(props.cdnDirectory);

  const playVideo = () => {
    const waitForVideo = () => {
      video.current.addEventListener('canplaythrough', () => {
        if (video.current) {
          video.current.play();
        }
      });
    };

    if (video.current.readyState === 4) {
      video.current.play();
    } else {
      waitForVideo();
    }
  };

  React.useEffect(playVideo, []);

  return (
    <video {...classes('c-answer__video')} muted loop ref={video} playsInline autoPlay>
      <source src={`${cdnUrl(props.asset)}.webm`} type="video/webm" />
      <source src={`${cdnUrl(props.asset)}.mp4`} type="video/mp4" />
    </video>
  );
};

export default QuestionVideo;
