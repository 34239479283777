import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion

import { numericField } from 'components/animations/utils';


type SlideInFrom = 'left' | 'right';

interface Props {
  elementType:         string;
  children?:           React.ReactNode;
  slideInFrom:         SlideInFrom;
  delay?:              number;
  damping?:            number;
  mass?:               number;
  stiffness?:          number;
  [otherProp: string]: any;
}

const AnimationSlideIn: React.FC<Props> = (props: Props) => {

  const {
    elementType,  // 'div', 'span', 'img', etc.
    children,     // Children (for an element which can accept children, such as a <div>).
    slideInFrom,  // Which side to slide the element in from ('left' or 'right').
    delay,        // @see https://www.framer.com/api/motion/types/#orchestration.delay
    damping,      // @see https://www.framer.com/api/motion/types/#spring.damping
    mass,         // @see https://www.framer.com/api/motion/types/#spring.mass
    stiffness,    // @see https://www.framer.com/api/motion/types/#spring.stiffness
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  const initialX: number = (slideInFrom === 'left' ? -window.innerWidth : window.innerWidth);

  const allProps = {
    ...otherProps,
    initial:    { x: initialX },  // @see https://www.framer.com/api/motion/animation/#mount-animations
    animate:    { x: 0        },  // @see https://www.framer.com/api/motion/animation/#target-object
    transition: {                 // @see https://www.framer.com/api/motion/animation/#transitions
      delay: numericField(delay, 0),
      x: {                        // @see https://www.framer.com/api/motion/component/#transform
        type:      'spring',      // @see https://www.framer.com/api/motion/types/#spring
        restSpeed: 0.0001,        // @see https://www.framer.com/api/motion/types/#spring.restspeed
        damping:   numericField(damping,   12),
        mass:      numericField(mass,      0.2),
        stiffness: numericField(stiffness, 200)
      }
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationSlideIn;
