export default {
  'www.mueller.de': {
    'retailerKey': 'mueller',
    'intro': true,
    'result': false,
    'facebook': '',
    'twitter': '',
    'instagram': '',
    'resultsCopy': '',
    'image': 'mueller.png',
    'buyNow': {
      discoverUniversity: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      realmOfMagic: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      tinyLiving: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      cityLiving: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      dineOut: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      moschino: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      getFamous: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      spaDay: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      vintageGlamour: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      getToWork: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      strangeville: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      fitness: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      islandLiving: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      jungleAdventures: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      romanticGarden: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      seasons: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      parenthood: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      toddler: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      catsAndDogs: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      outdoorRetreat: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      myFirstPet: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      getTogether: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      vampires: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true',
      bowlingNight: 'https://www.mueller.de/search/?q=Die+Sims+4&ms=true'
    }
  },
  'www.fnac.es': {
    'retailerKey': 'fnac',
    'intro': true,
    'result': true,
    'facebook': '',
    'twitter': 'https://twitter.com/Fnac_Gamers',
    'instagram': '',
    'resultsCopy': '',
    'image': 'fnac.png',
    'buyNow': {
      discoverUniversity: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      realmOfMagic: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      tinyLiving: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      cityLiving: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      dineOut: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      moschino: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      getFamous: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      spaDay: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      vintageGlamour: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      getToWork: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      strangeville: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      fitness: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      islandLiving: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      jungleAdventures: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      romanticGarden: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      seasons: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      parenthood: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      toddler: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      catsAndDogs: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      outdoorRetreat: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      myFirstPet: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      getTogether: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      vampires: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC',
      bowlingNight: 'https://www.fnac.es/n111619/Los-Sims-4/Videojuego-para-PC'
    }
  },
  'www.ea.es': {
    'retailerKey': 'ea',
    'intro': false,
    'result': true,
    'facebook': '',
    'twitter': 'https://twitter.com/LosSimsES',
    'instagram': '',
    'resultsCopy': '',
    'image': 'ea.png',
    'buyNow': {
      discoverUniversity: 'http://bit.ly/2UstoTp',
      realmOfMagic: 'http://bit.ly/2UstoTp',
      tinyLiving: 'http://bit.ly/2UstoTp',
      cityLiving: 'http://bit.ly/2UstoTp',
      dineOut: 'http://bit.ly/2UstoTp',
      moschino: 'http://bit.ly/2UstoTp',
      getFamous: 'http://bit.ly/2UstoTp',
      spaDay: 'http://bit.ly/2UstoTp',
      vintageGlamour: 'http://bit.ly/2UstoTp',
      getToWork: 'http://bit.ly/2UstoTp',
      strangeville: 'http://bit.ly/2UstoTp',
      fitness: 'http://bit.ly/2UstoTp',
      islandLiving: 'http://bit.ly/2UstoTp',
      jungleAdventures: 'http://bit.ly/2UstoTp',
      romanticGarden: 'http://bit.ly/2UstoTp',
      seasons: 'http://bit.ly/2UstoTp',
      parenthood: 'http://bit.ly/2UstoTp',
      toddler: 'http://bit.ly/2UstoTp',
      catsAndDogs: 'http://bit.ly/2UstoTp',
      outdoorRetreat: 'http://bit.ly/2UstoTp',
      myFirstPet: 'http://bit.ly/2UstoTp',
      getTogether: 'http://bit.ly/2UstoTp',
      vampires: 'http://bit.ly/2UstoTp',
      bowlingNight: 'http://bit.ly/2UstoTp'
    }
  },
  'www.mediamarkt.es': {
    'retailerKey': 'mediamarkt',
    'intro': true,
    'result': true,
    'facebook': 'https://www.facebook.com/mediamarktes',
    'twitter': 'https://twitter.com/MediaMarktGames',
    'instagram': 'https://www.instagram.com/mediamarkt_es/',
    'resultsCopy': '¡Revive todas las entregas de SIMS con nosotros!',
    'image': 'mediamarkt.png',
    'buyNow': {
      discoverUniversity: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      realmOfMagic: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      tinyLiving: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      cityLiving: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      dineOut: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      moschino: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      getFamous: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      spaDay: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      vintageGlamour: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      getToWork: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      strangeville: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      fitness: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      islandLiving: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      jungleAdventures: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      romanticGarden: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      seasons: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      parenthood: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      toddler: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      catsAndDogs: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      outdoorRetreat: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      myFirstPet: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      getTogether: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      vampires: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766',
      bowlingNight: 'https://www.mediamarkt.es/es/search.html?query=1400539+1458133+1264204+1373596+1436163+1422253+1457509+1346169+1436164+1379274+1467999+1468000+1208514+1288957+1271314&searchProfile=onlineshop&channel=mmeses&outlet=1766'
    }
  },
  'www.game.es': {
    'retailerKey': 'game',
    'intro': true,
    'result': true,
    'facebook': 'https://es-es.facebook.com/VideojuegosGAME',
    'twitter': 'https://twitter.com/VideojuegosGAME',
    'instagram': 'https://www.instagram.com/videojuegosgame/?hl=es',
    'resultsCopy': '',
    'image': 'game.png',
    'buyNow': {
      discoverUniversity: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      realmOfMagic: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      tinyLiving: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      cityLiving: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      dineOut: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      moschino: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      getFamous: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      spaDay: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      vintageGlamour: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      getToWork: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      strangeville: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      fitness: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      islandLiving: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      jungleAdventures: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      romanticGarden: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      seasons: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      parenthood: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      toddler: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      catsAndDogs: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      outdoorRetreat: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      myFirstPet: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      getTogether: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      vampires: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS',
      bowlingNight: 'https://www.game.es/buscar/los-sims-4?utm_source=EA&utm_medium=EXTERNO&utm_content=PRODUCTO&utm_campaign=SIMS'
    }
  },
  'www.gamestop.com': {
    'retailerKey': 'gamestop',
    'intro': false,
    'result': false,
    'facebook': '',
    'twitter': '',
    'instagram': '',
    'resultsCopy': '',
    'image': '',
    'buyNow': {
      discoverUniversity: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-discover-university/11098082.html',
      realmOfMagic: 'https://www.gamestop.com/video-games/pc/products/the-sims-4-realm-of-magic-game-pack/11096653.html',
      tinyLiving: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-tiny-living-stuff-pack/11099619.html',
      cityLiving: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-city-living/10136091.html',
      dineOut: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-dine-out-pack/10130924.html',
      moschino: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-moschino-stuff-pack/11096451.html',
      getFamous: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-get-famous/10170705.html',
      spaDay: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-spa-day/10124791.html',
      vintageGlamour: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-vintage-glamour-stuff-pack/10139438.html',
      getToWork: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-get-to-work/10120142.html',
      strangeville: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-strangerville/10173656.html',
      fitness: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-fitness-stuff/10149886.html',
      islandLiving: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-island-living/11094368.html',
      jungleAdventures: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-jungle-adventure-pack/10159097.html',
      romanticGarden: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-romantic-garden-stuff/10128638.html',
      seasons: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-seasons/10163277.html',
      parenthood: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-parenthood-pack/10149253.html',
      toddler: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-toddler-stuff/10152389.html',
      catsAndDogs: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-cats-dogs/10152861.html',
      outdoorRetreat: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-outdoor-retreat/10119756.html',
      myFirstPet: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-my-first-pet-stuff/10159728.html',
      getTogether: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-get-together/10124792.html',
      vampires: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-vampires-pack/10141844.html',
      bowlingNight: 'https://www.gamestop.com/video-games/pc/games/products/the-sims-4-bowling-night-stuff-pack/10146823.html'
    }
  },
  'www.bestbuy.com': {
    'retailerKey': 'bestbuy',
    'intro': false,
    'result': false,
    'facebook': '',
    'twitter': '',
    'instagram': '',
    'resultsCopy': '',
    'image': '',
    'buyNow': {
      discoverUniversity: 'https://www.bestbuy.com/site/the-sims-4-discover-university-expansion-pack-windows-digital/6387329.p?skuId=6387329',
      realmOfMagic: 'https://www.bestbuy.com/site/the-sims-4-realm-of-magic-game-pack-macwindows-digital/6373056.p?skuId=6373056',
      tinyLiving: 'https://www.bestbuy.com/site/the-sims-4-tiny-living-stuff-pack-macwindows-digital/6398470.p?skuId=6398470',
      cityLiving: 'https://www.bestbuy.com/site/the-sims-4-city-living-windows-digital/5624077.p?skuId=5624077',
      dineOut: 'https://www.bestbuy.com/site/the-sims-4-dine-out-macwindows-digital/5315500.p?skuId=5315500',
      moschino: 'https://www.bestbuy.com/site/the-sims-4-moschino-stuff-pack-macwindows-digital/6367487.p?skuId=6367487',
      getFamous: 'https://www.bestbuy.com/site/the-sims-4-get-famous-macwindows-digital/6309117.p?skuId=6309117',
      spaDay: 'https://www.bestbuy.com/site/the-sims-4-spa-day-macwindows-digital/4338300.p?skuId=4338300',
      vintageGlamour: 'https://www.bestbuy.com/site/searchpage.jsp?st=the+sims+4+&_dyncharset=UTF-8&_dynSessConf=&id=pcat17071&type=page&sc=Global&cp=1&nrp=&sp=&qp=&list=n&af=true&iht=y&usc=All+Categories&ks=960&keys=keys',
      getToWork: 'https://www.bestbuy.com/site/the-sims-4-get-to-work-macwindows-digital/5898015.p?skuId=5898015',
      strangeville: 'https://www.bestbuy.com/site/the-sims-4-strangerville-macwindows-digital/6335482.p?skuId=6335482',
      fitness: 'https://www.bestbuy.com/site/the-sims-4-fitness-stuff-macwindows-digital/5944800.p?skuId=5944800',
      islandLiving: 'https://www.bestbuy.com/site/the-sims-4-island-living-macwindows/6348478.p?skuId=6348478',
      jungleAdventures: 'https://www.bestbuy.com/site/the-sims-4-jungle-adventure-macwindows-digital/6212945.p?skuId=6212945',
      romanticGarden: 'https://www.bestbuy.com/site/the-sims-4-romantic-garden-stuff-xbox-one-digital/6201450.p?skuId=6201450',
      seasons: 'https://www.bestbuy.com/site/the-sims-4-seasons-expansion-pack-macwindows/6238434.p?skuId=6238434',
      parenthood: 'https://www.bestbuy.com/site/the-sims-4-parenthood-macwindows-digital/5901993.p?skuId=5901993',
      toddler: 'https://www.bestbuy.com/site/searchpage.jsp?st=the+sims+4&_dyncharset=UTF-8&_dynSessConf=&id=pcat17071&type=page&sc=Global&cp=1&nrp=&sp=&qp=&list=n&af=true&iht=y&usc=All+Categories&ks=960&keys=keys',
      catsAndDogs: 'https://www.bestbuy.com/site/sims-4-cats-and-dogs-expansion-pack-windows-digital/6099984.p?skuId=6099984',
      outdoorRetreat: 'https://www.bestbuy.com/site/the-sims-4-outdoor-retreat-macwindows-digital/3098064.p?skuId=3098064',
      myFirstPet: 'https://www.bestbuy.com/site/the-sims-4-my-first-pet-stuff-macwindows-digital/6228500.p?skuId=6228500',
      getTogether: 'https://www.bestbuy.com/site/the-sims-4-get-together-macwindows-digital/4694600.p?skuId=4694600',
      vampires: 'https://www.bestbuy.com/site/the-sims-4-vampires-windows-digital/5746800.p?skuId=5746800',
      bowlingNight: 'https://www.bestbuy.com/site/the-sims-4-bowling-night-stuff-macwindows-digital/5822519.p?skuId=5822519'
    }
  }
};
