import screenQuestionGenerator from 'components/screens/ScreenQuestion/ScreenQuestion';
import { ScreenAnswer }        from 'game-constants';


export const ScreenQ1 = screenQuestionGenerator({
  translationKey: 'screenQuestion1',
  cdnDirectory:   '01-question-1/',
  assetA:         'Q1_BUSY',
  assetB:         'Q1_SCENIC',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q1BusyLively,
  screenAnswerB:  ScreenAnswer.Q1QuaintScenic
});

export const ScreenQ2 = screenQuestionGenerator({
  translationKey: 'screenQuestion2',
  cdnDirectory:   '02-question-2/',
  assetA:         'Q2_FRIENDSHIPS',
  assetB:         'Q2_FORTUNE',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q2LastingFriendships,
  screenAnswerB:  ScreenAnswer.Q2LastingFortune
});

export const ScreenQ3 = screenQuestionGenerator({
  translationKey: 'screenQuestion3',
  cdnDirectory:   '03-question-3/',
  assetA:         'Q3_GENIUS',
  assetB:         'Q3_CREATIVE',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q3IntellectualGenius,
  screenAnswerB:  ScreenAnswer.Q3CreativeMaster
});

export const ScreenQ4 = screenQuestionGenerator({
  translationKey: 'screenQuestion4',
  cdnDirectory:   '04-question-4/',
  assetA:         'Q4_PETTING',
  assetB:         'Q4_PARTYING',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q4PettingAnAnimal,
  screenAnswerB:  ScreenAnswer.Q4PartyingLikeAnAnimal
});

export const ScreenQ5 = screenQuestionGenerator({
  translationKey: 'screenQuestion5',
  cdnDirectory:   '05-question-5/',
  assetA:         'Q5_FAMILIAR',
  assetB:         'Q5_OTHER_WORLDLY',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q5FamiliarBeings,
  screenAnswerB:  ScreenAnswer.Q5OtherWorldlyCompanions
});

export const ScreenQ6 = screenQuestionGenerator({
  translationKey: 'screenQuestion6',
  cdnDirectory:   '06-question-6/',
  assetA:         'Q6_IMPRESS',
  assetB:         'Q6_WOW',
  imgQuestion:    'question.png',
  screenAnswerA:  ScreenAnswer.Q6NewRecipes,
  screenAnswerB:  ScreenAnswer.Q6Classics
});
