import React      from 'react';          // @see https://www.npmjs.com/package/react
import { motion } from 'framer-motion';  // @see https://www.npmjs.com/package/framer-motion

import { numericField } from 'components/animations/utils';


interface Props {
  elementType:         string;
  children?:           React.ReactNode;
  triggered:           boolean;
  magnifyRatio?:       number;
  duration?:           number;
  delay?:              number;
  [otherProp: string]: any;
}

const AnimationPulse: React.FC<Props> = (props: Props) => {

  const {
    elementType,   // 'div', 'span', 'img', etc.
    children,      // Children (for an element which can accept children, such as a <div>).
    triggered,     // Set this to `false` initially. Change to `true` to trigger the animation.
    magnifyRatio,  // Amount to magnify the element (eg: `2.0` will magnify the element to twice its original size).
    delay,      // Duration (in seconds) of the animation.
    duration,      // Duration (in seconds) of the animation.
    ...otherProps
  } = props;

  // @see https://www.framer.com/api/motion/component/
  // @ts-ignore
  const component = motion[elementType];

  let scale: number | number[] = 1;

  if (triggered) {
    scale = [
      1,
      numericField(magnifyRatio, 3),
      1
    ];  // Keyframes for the animation.
  }

  const allProps = {
    ...otherProps,
    animate:    { scale },                    // @see https://www.framer.com/api/motion/animation/#target-object
    delay:      numericField(delay, 2),
    transition: {                             // @see https://www.framer.com/api/motion/animation/#transitions
      duration: numericField(duration, 0.8),
      ease:     'easeInOut'
    }
  };

  return React.createElement(component, allProps, children);

};

export default AnimationPulse;
